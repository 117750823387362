'use strict';

(function app() {
  var spBreak = 768;
  var menuOpen = false;
  var offsetY = window.pageYOffset;

  function isMobile() {
    return window.matchMedia('(max-width: ' + spBreak + 'px)').matches;
  }

  function detectBrowsers() {
    var html = $('html');
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('mac') >= 0) {
      html.addClass('is-mac');
    }
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
        html.addClass('is-chrome');
      } else {
        html.addClass('is-safari');
      }
    }
    if (ua.indexOf('msie ') > -1 || ua.indexOf('trident/') > -1) {
      html.addClass('is-ie');
    }
    if (ua.indexOf('firefox') > -1) {
      html.addClass('is-firefox');
    }
    if (ua.indexOf('android') > -1) {
      html.addClass('is-android');
    }
    if (ua.match(/(iphone|ipod|ipad)/)) {
      html.addClass('is-ios');
    }
    if (ua.indexOf('edg/') > -1) {
      html.removeClass('is-chrome');
      html.addClass('is-chromium');
    }
  }

  function tabletViewport() {
    var viewport = document.getElementById('viewport');
    var ua = '';
    function setViewport() {
      var portrait = window.matchMedia('(orientation: portrait)').matches;
      if (window.screen.width < 375 && portrait) {
        viewport.setAttribute('content', 'width=375, user-scalable=0');
      } else if (
        (window.screen.width >= 768 && window.screen.width <= 1199)
        || (window.screen.width < 768 && window.screen.height >= 768 && !portrait)
      ) {
        viewport.setAttribute('content', 'width=1300, user-scalable=0');
        ua = navigator.userAgent.toLowerCase();
        if (
          (/macintosh/i.test(ua)
            && navigator.maxTouchPoints
            && navigator.maxTouchPoints > 1)
          || (ua.match(/(iphone|ipod|ipad)/) && !isMobile())
          || (ua.indexOf('android') > -1 && !isMobile())
        ) {
          $('html').addClass('is-tablet');
        }
      } else {
        viewport.setAttribute(
          'content',
          'width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=0'
        );
        $('html').removeClass('is-tablet');
      }
    }
    setViewport();
    $(window).on('load resize', setViewport);
  }

  function smoothScroll() {
    var anchors = $('a[href*="#"]:not([href="#"])');
    var headerHeight = 0;
    var speed = 500;
    var timeout = 0;
    var position = 0;
    function triggerScroll(context) {
      var href = typeof context === 'string'
        ? context
        : '#' + $(context).attr('href').split('#')[1];
      if (!$(context).hasClass('no-scroll') && $(href).length) {
        position = $(href).offset().top - headerHeight;
        $('body, html').animate({ scrollTop: position }, speed, 'swing');
        return false;
      }
      return true;
    }
    setTimeout(function setTimerHTMLVisibility() {
      window.scroll(0, 0);
      $('html').removeClass('is-loading').addClass('is-visible');
    }, 1);
    if (window.location.hash) {
      window.scroll(0, 0);
      if (
        navigator.userAgent.indexOf('MSIE ') > -1
        || navigator.userAgent.indexOf('Trident/') > -1
      ) {
        timeout = 0;
      } else {
        timeout = 500;
      }
      setTimeout(function setTimerTriggerScroll() {
        triggerScroll(window.location.hash);
      }, timeout);
    }
    anchors.on('click', function onClickAnchor() {
      if ($('body').hasClass('has-menu')) {
        $('.js-button-menu').click();
      }
      return triggerScroll(this);
    });
  }

  function accordionFooter() {
    if (!$('.js-accordion-footer').length) return;
    $('.js-accordion-footer .title').on('click', function onClickAccordion() {
      $('.js-accordion-footer .title').toggleClass('is-accordion');
      $('.toggle').toggleClass('is-active');
      return false;
    });
  }

  function openMenu() {
    offsetY = window.pageYOffset;
    $('body')
      .css({
        top: -offsetY + 'px'
      })
      .addClass('has-menu');
    $('.js-navigation').stop().fadeIn();
    menuOpen = true;
  }

  function closeMenu() {
    $('body')
      .css({
        top: 'auto'
      })
      .removeClass('has-menu');
    $(window).scrollTop(offsetY);
    $('.js-navigation').stop().fadeOut();
    menuOpen = false;
  }

  function navigation() {
    if (menuOpen) return;

    $('.js-button-menu').click(function onCLickOpen() {
      $(this).toggleClass('is-active');
      if ($(this).hasClass('is-active')) {
        openMenu();
      } else {
        closeMenu();
      }
      return false;
    });

    $('.js-close-menu').click(function onCLickClose() {
      closeMenu();
      $('.js-button-menu').removeClass('is-active');
      return false;
    });

    function heightNavigation() {
      var heightWindow = $(window).innerHeight();
      if ($('.js-navigation').length) {
        $('.js-navigation').css({
          height: heightWindow
        });
      }
    }

    heightNavigation();
    $(window).on('load resize', function resizeNavigation() {
      heightNavigation();
    });
  }
  
  function keyvisualSlider() {
    if (!$('.js-keyvisual-slider').length) return;
    new Swiper('.js-keyvisual-slider', {
      loop: true,
      speed: 1500,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 4200,
        disableOnInteraction: false
      }
    });
  }

  function videoSlider() {
    if (!$('.js-video-slider').length) return;
    new Swiper('.js-video-slider', {
	  loop: true,
	  centeredSlides: true,
	  slidesPerView: 3,
	  navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	  },
	});
  }

  function slideGallery() {
    var slider = $('.js-slide-gallery');
    var sliderWrapper = slider.find('.swiper-wrapper');
    var sliderWrapperHTML = sliderWrapper.html();
    if (!$('.js-slide-gallery').length) return;
    sliderWrapper.append(sliderWrapperHTML);
    new Swiper('.js-slide-gallery', {
      loop: true,
      speed: 5000,
      slidesPerView: 'auto',
      allowTouchMove: false,
      autoplay: {
        delay: 0
      }
    });
  }

  function initWow() {
    if ($('.wow').length) {
      new WOW().init();
    }
    if ($('.js-offset').length) {
      $('.js-offset').each(function loopWow() {
        $(this).attr('data-wow-offset', $(this).innerHeight() / 2);
      });
    }
  }

  function headerScroll() {
    var distance = $('.js-keyvisual').length
      ? $('.js-keyvisual').innerHeight()
      : 50;
    function headerFixed() {
      if (menuOpen) return;
      $('.l-header__btn-menu').toggleClass(
        'l-header__btn-menu--fixed',
        $(window).scrollTop() > distance
      );
    }
    headerFixed();
    $(window).on('load scroll resize', headerFixed);
  }

  function accordionFaq() {
    if (!$('.js-accordion-faq').length) return;
    $('.js-accordion-faq')
      .find('.js-accordion-heading')
      .on('click', function onClick() {
        $(this)
          .stop()
          .toggleClass('is-active')
          .parents('.js-accordion-faq')
          .find('.js-accordion-answer')
          .stop()
          .slideToggle();
      });
  }

  function authorSlider() {
    if (!$('.js-slider-author').length) return false;
    $('.js-slider-author').each(function loopSlider(i, container) {
      // Variable declaration
      var limitDesktop = $(container).data('limit-desktop') || 4;
      var limitMobile = $(container).data('limit-mobile') || 3;
      var sliderWrapper = $(container).find('.swiper-wrapper');
      var sliderWrapperHTML = $(container).find('.swiper-wrapper').html();
      var sliderLength = $(container).find('.swiper-slide').length;
      var sliderBase = $(container).find('.slider-viewport');
      var sliderDesktop = null;
      var sliderMobile = null;
      var swiper = null;
      var bullets;
      var bulletHTML;
      var bulletIndex;
      var lastViewport = isMobile() ? 'mobile' : 'desktop';
      var sliderOption = {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 18,
        breakpoints: {
          768.02: {
            slidesPerView: 4,
            spaceBetween: 100
          }
        },
        pagination: {
          el: $(container).find('.swiper-pagination')[0],
          clickable: true
        }
      };

      // Create function
      function create() {
        if (limitMobile < sliderLength && sliderLength <= limitDesktop) {
          if (isMobile()) {
            $(container).removeClass('is-disabled');
            sliderDesktop.detach();
            $(container).prepend(sliderMobile);
          } else {
            $(container).addClass('is-disabled');
            sliderMobile.detach();
            $(container).prepend(sliderDesktop);
          }
        }
      }

      // Re-create on resize
      function update() {
        var currentViewport = isMobile() ? 'mobile' : 'desktop';
        if (lastViewport !== currentViewport) {
          lastViewport = currentViewport;
          create();
        }
      }

      // Set-up desktop & mobile slider
      if (sliderLength <= limitMobile) {
        $(container).addClass('is-disabled');
      } else if (limitMobile < sliderLength && sliderLength <= limitDesktop) {
        sliderDesktop = sliderBase.clone();
        sliderWrapper.append(sliderWrapperHTML);
        swiper = new Swiper(sliderBase[0], sliderOption);
        sliderMobile = sliderBase;
      } else {
        sliderWrapper.append(sliderWrapperHTML);
        swiper = new Swiper(sliderBase[0], sliderOption);
      }

      // Custom pagination
      if ($(container).find('.custom-pagination').length && swiper) {
        bulletHTML = '<span class="custom-pagination-bullet"><span>';
        for (bulletIndex = 0; bulletIndex < sliderLength;) {
          $(container).find('.custom-pagination').append(bulletHTML);
          bulletIndex += 1;
        }
        bullets = $(container).find('.custom-pagination-bullet');
        bullets.each(function loopBullets(index, bullet) {
          if (index === 0) $(bullet).addClass('is-active');
          $(bullet).on('click', function onClickBullet() {
            swiper.slideToLoop(index);
          });
        });
        swiper.on('slideChange', function onSlideChangeSwiper() {
          var index = swiper.realIndex % sliderLength;
          $(container)
            .find('.custom-pagination-bullet')
            .removeClass('is-active')
            .eq(index)
            .addClass('is-active');
        });
      }

      // Initialize
      create();
      $(window).on('load resize rotate', update);
    });
    return true;
  }

  function sliderAffiliated() {
    if (!$('.js-slider-affiliated').length) return false;
    $('.js-slider-affiliated').each(function loopSlider(i, container) {
      // Variable declaration
      var limitDesktop = $(container).data('limit-desktop') || 4;
      var limitMobile = $(container).data('limit-mobile') || 3;
      var sliderWrapper = $(container).find('.swiper-wrapper');
      var sliderWrapperHTML = $(container).find('.swiper-wrapper').html();
      var sliderLength = $(container).find('.swiper-slide').length;
      var sliderBase = $(container).find('.slider-viewport');
      var sliderDesktop = null;
      var sliderMobile = null;
      var swiper = null;
      var bullets;
      var bulletHTML;
      var bulletIndex;
      var lastViewport = isMobile() ? 'mobile' : 'desktop';
      var sliderOption = {
        loop: true,
        slidesPerView: 2,
        spaceBetween: 26,
        breakpoints: {
          768.02: {
            slidesPerView: 3,
            spaceBetween: 58
          }
        },
        pagination: {
          el: $(container).find('.swiper-pagination')[0],
          clickable: true
        }
      };

      // Create function
      function create() {
        if (limitMobile < sliderLength && sliderLength <= limitDesktop) {
          if (isMobile()) {
            $(container).removeClass('is-disabled');
            sliderDesktop.detach();
            $(container).prepend(sliderMobile);
          } else {
            $(container).addClass('is-disabled');
            sliderMobile.detach();
            $(container).prepend(sliderDesktop);
          }
        }
      }

      // Re-create on resize
      function update() {
        var currentViewport = isMobile() ? 'mobile' : 'desktop';
        if (lastViewport !== currentViewport) {
          lastViewport = currentViewport;
          create();
        }
      }

      // Set-up desktop & mobile slider
      if (sliderLength <= limitMobile) {
        $(container).addClass('is-disabled');
      } else if (limitMobile < sliderLength && sliderLength <= limitDesktop) {
        sliderDesktop = sliderBase.clone();
        sliderWrapper.append(sliderWrapperHTML);
        swiper = new Swiper(sliderBase[0], sliderOption);
        sliderMobile = sliderBase;
      } else {
        sliderWrapper.append(sliderWrapperHTML);
        swiper = new Swiper(sliderBase[0], sliderOption);
      }

      // Custom pagination
      if ($(container).find('.custom-pagination').length && swiper) {
        bulletHTML = '<span class="custom-pagination-bullet"><span>';
        for (bulletIndex = 0; bulletIndex < sliderLength;) {
          $(container).find('.custom-pagination').append(bulletHTML);
          bulletIndex += 1;
        }
        bullets = $(container).find('.custom-pagination-bullet');
        bullets.each(function loopBullets(index, bullet) {
          if (index === 0) $(bullet).addClass('is-active');
          $(bullet).on('click', function onClickBullet() {
            swiper.slideToLoop(index);
          });
        });
        swiper.on('slideChange', function onSlideChangeSwiper() {
          var index = swiper.realIndex % sliderLength;
          $(container)
            .find('.custom-pagination-bullet')
            .removeClass('is-active')
            .eq(index)
            .addClass('is-active');
        });
      }

      // Initialize
      create();
      $(window).on('load resize rotate', update);
    });
    return true;
  }

  function accordionAffiliated() {
    $(window).on('resize load', function onResizeWindow() {
      if (isMobile()) {
        $('.js-button-accordion').click(function toggleAccordion() {
          $(this).parents('.has-sub-accordion').toggleClass('is-active');
          $(this)
            .parents('.has-sub-accordion')
            .find('.js-accordion')
            .stop()
            .slideToggle();
          return false;
        });
      }
    });
  }

  function accordionPostContent() {
    $('.ez-toc-title').click(function toggleAccordion() {
      $(this).parents('.has-sub').toggleClass('is-active');
      $(this).parents('.has-sub').find('.ez-toc-list-level-1').stop()
        .slideToggle();
      return false;
    });
  }

  function sliderService() {
    if (!$('.js-slider-service').length) return false;
    $('.js-slider-service').each(function loopSlider(i, container) {
      // Variable declaration
      var limitDesktop = $(container).data('limit-desktop') || 4;
      var limitMobile = $(container).data('limit-mobile') || 3;
      var sliderWrapper = $(container).find('.swiper-wrapper');
      var sliderWrapperHTML = $(container).find('.swiper-wrapper').html();
      var sliderLength = $(container).find('.swiper-slide').length;
      var sliderBase = $(container).find('.slider-viewport');
      var sliderDesktop = null;
      var sliderMobile = null;
      var swiper = null;
      var bullets;
      var bulletHTML;
      var bulletIndex;
      var lastViewport = isMobile() ? 'mobile' : 'desktop';
      var sliderOption = {
        loop: true,
        slidesPerView: 1,
        navigation: {
          nextEl: '.swiper-button-next'
        },
        pagination: {
          el: $(container).find('.swiper-pagination')[0],
          clickable: true
        }
      };

      // Create function
      function create() {
        if (limitMobile < sliderLength && sliderLength <= limitDesktop) {
          if (isMobile()) {
            $(container).removeClass('is-disabled');
            sliderDesktop.detach();
            $(container).prepend(sliderMobile);
          } else {
            $(container).addClass('is-disabled');
            sliderMobile.detach();
            $(container).prepend(sliderDesktop);
          }
        }
      }

      // Re-create on resize
      function update() {
        var currentViewport = isMobile() ? 'mobile' : 'desktop';
        if (lastViewport !== currentViewport) {
          lastViewport = currentViewport;
          create();
        }
      }

      // Set-up desktop & mobile slider
      if (sliderLength <= limitMobile) {
        $(container).addClass('is-disabled');
      } else if (limitMobile < sliderLength && sliderLength <= limitDesktop) {
        sliderDesktop = sliderBase.clone();
        sliderWrapper.append(sliderWrapperHTML);
        swiper = new Swiper(sliderBase[0], sliderOption);
        sliderMobile = sliderBase;
      } else {
        sliderWrapper.append(sliderWrapperHTML);
        swiper = new Swiper(sliderBase[0], sliderOption);
      }

      // Custom pagination
      if ($(container).find('.custom-pagination').length && swiper) {
        bulletHTML = '<span class="custom-pagination-bullet"><span>';
        for (bulletIndex = 0; bulletIndex < sliderLength;) {
          $(container).find('.custom-pagination').append(bulletHTML);
          bulletIndex += 1;
        }
        bullets = $(container).find('.custom-pagination-bullet');
        bullets.each(function loopBullets(index, bullet) {
          if (index === 0) $(bullet).addClass('is-active');
          $(bullet).on('click', function onClickBullet() {
            swiper.slideToLoop(index);
          });
        });
        swiper.on('slideChange', function onSlideChangeSwiper() {
          var index = swiper.realIndex % sliderLength;
          $(container)
            .find('.custom-pagination-bullet')
            .removeClass('is-active')
            .eq(index)
            .addClass('is-active');
        });
      }

      // Initialize
      create();
      $(window).on('load resize rotate', update);
    });
    return true;
  }

  function accordionMenuFooter() {
    $(window).on('resize load', function onResizeWindow() {
      if (isMobile()) {
        $('.js-button-navigation').off('click').on('click', function toggleAccordion() {
          $(this)
            .parents('.l-footer__nav-item__has-sub')
            .toggleClass('is-active');
          $(this)
            .parents('.l-footer__nav-item__has-sub')
            .find('.js-navigation-content')
            .stop()
            .slideToggle();
          return false;
        });
      }
    });
  }

  function accordionMenuHeader() {
    $(window).on('resize load', function onResizeWindow() {
      if (isMobile()) {
        $('.js-menu-navigation').off('click').on('click', function toggleAccordion() {
          $(this)
            .parents('.l-navigation__nav-item__has-sub')
            .toggleClass('is-active');
          $(this)
            .parents('.l-navigation__nav-item__has-sub')
            .find('.js-menu-content')
            .stop()
            .slideToggle();
          return false;
        });
      }
    });
  }
  
  function slideAnimation() {
	var startPos = 0,winScrollTop = 0;
	$(window).scroll(function(){
		//scroll animation
		const scroll =$(window).scrollTop();
		const windowHeight = $(window).height();
		/*
		$(".animation_item").each(function(){
			const scrollPos = $(this).offset().top;
			if (scroll > scrollPos - windowHeight + windowHeight/8) {
			$(this).addClass('fade_up');
			}
		});
		*/
		$(".fade_left").each(function(){
			const scrollPos = $(this).offset().top;
			if (scroll > scrollPos - windowHeight + windowHeight/4) {
			$(this).addClass('active');
			}
		});
	});
  }
  
//  $(window).on('load', function onLoadWindow() {
    window.WebFontConfig = {
      custom: {
        families: [
          'Noto Sans JP:n4,n7',
        ],
        urls: [
          'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap'
        ]
/*
        families: [
          'Noto Serif JP:n4,n6,n7',
          'Noto Sans JP:n4',
          'Parisienne:n4'
        ],
        urls: [
          'https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;600;700&family=Parisienne:wght@400&family=Noto+Sans+JP:wght@400&display=swap'
        ]
*/
      }
    };
    (function loadFont() {
      var wf = document.createElement('script');
      var s;
      wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
      wf.type = 'text/javascript';
      wf.async = 'true';
      s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(wf, s);
    }());
//  });

  $(function init() {
    detectBrowsers();
    tabletViewport();
    smoothScroll();
    accordionFooter();
    navigation();
    keyvisualSlider();
    videoSlider();
    slideGallery();
    initWow();
    headerScroll();
    accordionFaq();
    accordionAffiliated();
    authorSlider();
    sliderAffiliated();
    accordionPostContent();
    sliderService();
    accordionMenuFooter();
    accordionMenuHeader();
	slideAnimation();
  });
}());
